<template>
  <div
    id="header"
    class="flex_box flex_center"
    :class="isBg ? 'befor-bg' : 'after-bg'"
  >
    <div class="content flex_box flex_between">
      <img
        v-show="isBg"
        class="logo pointer"
        src="@/assets/img/logo.png"
        alt=""
        @click="toPage('/home')"
      />
      <img
        v-show="!isBg"
        class="logo pointer"
        src="@/assets/img/logo1.png"
        alt=""
        @click="toPage('/home')"
      />
      <div class="content-right flex_box">
        <!-- 导航 -->
        <ul class="my-navbar nav-i flex_box flex_around font_18">
          <li
            :class="
              'color_white title_font ' +
              (item.children ? 'is-li' : '') +
              (isBg ? ' befor-color' : ' after-color')
            "
            v-for="(item, index) in navList"
            :key="index"
            @click="toPage(item.path)"
          >
            <span
              :style="
                $route.path.includes(item.path)
                  ? 'font-size:1.25rem;opacity: 1'
                  : 'font-size:1.13rem;opacity:.9'
              "
              >{{ onLang == 1 ? item.name : item.ename }}</span
            >
            <div
              :class="
                ($route.path.includes(item.path) ? 'solids' : 'solids-s') +
                (isBg ? ' befor-bac' : ' after-bac')
              "
            ></div>
            <div class="h-80" v-if="isBg && item.children"></div>
            <!-- 子导航 中 -->
            <ul
              :class="{
                'chilren-nav': true,
                'chilren-nav-bg': !isBg,
                'chilren-black-bg': isBg,
              }"
            >
              <li
                class="chilren-li cchilren-li flex flex-col-center"
                v-for="i in item.children"
                :key="i.type"
                @click.stop="toPage(item.path, i.path)"
              >
                <!-- <img :src="require('../assets/img/'+i.img +($route.path.includes(i.path) ? '-c' : '')+'.png')" alt="" style="width: auto; height: 1.4rem"> -->
                <i
                  :class="{
                    'nav-active': $route.path.includes(i.path),
                    'nav-next-active': $route.path.includes(i.path) && !isBg,
                  }"
                  >{{ onLang == 1 ? i.name : i.ename }}</i
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  props: {
    //   isBg: {
    //     type: Boolean,
    //     requerd: false,
    //     default: true
    //   } // 显示背景
  },
  data() {
    return {
      navList: [
        { name: "首页", ename: "Home", path: "/home" },
        {
          name: "解决方案",
          ename: "Voyages",
          path: "/solution",
          children: [
            {
              name: "智慧医疗",
              ename: "Hot Voyages",
              path: "/smartMedicalCare",
              img: "cline",
            },
            {
              name: "智慧政务",
              ename: "Domestic Voyages",
              path: "/smartGovernment",
              img: "cline",
            },
            {
              name: "智慧乡村",
              ename: "Domestic Voyages",
              path: "/smartVillage",
              img: "cline",
            },
            {
              name: "系统集成",
              ename: "Domestic Voyages",
              path: "/systemIntegration",
              img: "cline",
            },
            {
              name: "数字孪生",
              ename: "Domestic Voyages",
              path: "/digitalTwin",
              img: "cline",
            },
          ],
        },
        { name: "案例展示", ename: "Example", path: "/example" },
        { name: "合作客户", ename: "Vessels", path: "/home#vessels" },
        { name: "关于我们", ename: "About Us", path: "/about" },
      ],

      langList: [
        { id: 1, title: "中文" },
        { id: 2, title: "英文" },
      ],
      elangList: [
        { id: 1, title: "Chinese" },
        { id: 2, title: "English" },
      ],
      onLang: sessionStorage.getItem("lang") != 2 ? 1 : 2, // 中英文,
      isBg: true,
    };
  },
  created() {
    this.scrollWatch();
  },
  mounted() {},
  methods: {
    // 监听页面滚动
    scrollWatch() {
      window.addEventListener("scroll", (e) => {
        // console.log('document.documentElement.scrollTop',document.documentElement.scrollTop);
        this.isBg = document.documentElement.scrollTop <= 100 ? true : false;
      });
    },
    // 路由跳转
    toPage(url, curl) {
      // console.log('this.isBg',this.isBg);
      const urls = url + (curl ? curl : "");
      this.$router.push(urls);
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$route.hash && this.$route.hash.split("#")[1]) {
            document.querySelector(this.$route.hash).scrollIntoView({
              behavior: "smooth",
            });
          }
        }, 10);
      });
    },
  },
};
</script>

<style scoped lang="scss">
#header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  min-width: 1200px;
  transition: 0.2s all ease-in;
  .content {
    width: 1200px;
    height: 5rem;
    .logo {
      width: 250px;
      height: 40px;
    }
    .content-right {
      height: 5rem;
      margin-right: 40px;
      .nav-i > li {
        padding: 0 40px;
      }
      .my-navbar {
        margin: auto 0;
        font-size: 1.13rem;

        // font-family: Noto Sans SC;
        font-weight: 300;
        > li {
          position: relative;
          cursor: pointer;
          line-height: 4.875rem;
          display: flex;
          flex-direction: column;
          .solids {
            margin-left: 50%;
            transform: translateX(-50%);
            width: 1.875rem;
            height: 0.111rem;

            transition: 0.5s;
          }
          .solids-s {
            width: 0;
            height: 2px;
          }
          .active {
            margin-left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 0.111rem;
            background-color: #fff;
            transition: 0.5s;
          }
        }

        /* 子导航背景 */
        .is-li:hover {
          & ~ .bg-c {
            position: fixed;
            height: 4rem;
            top: 5.05rem;
            left: 0;
            width: 100%;
          }
        }
        li:hover,
        .bg-c:hover {
          .solids-s {
            margin-left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 0.111rem;
            background-color: #fff;
            transition: 0.5s;
          }
          .chilren-nav {
            height: 240px;
          }
          .nav-active {
            font-weight: bold;
            color: #ffffff;
          }
          .nav-next-active {
            font-weight: bold;
            color: #1d3e83;
          }
        }

        /* 子导航 */
        .chilren-nav {
          position: absolute;
          font-size: 1rem;
          top: 80px;
          padding-top: 0;
          // margin: 0 auto;
          // min-width: 70rem;
          transition: 0.2s all ease-in-out;
          transition-property: background-color, height;
          height: 0;
          transform: translate(-40px);
          overflow: hidden;
          &-bg {
            background-color: #fff;
          }
          .chilren-li {
            color: #222222;
            &:hover {
              color: #2e549d;
            }
            i {
              font-style: normal;
            }
            line-height: 3rem;
            width: 160px;
            text-indent: 40px;
            > img {
              margin-right: 5px;
            }
          }
          //z-index: 9;
          //> li {
          //  margin-right: 2rem;
          //}
        }
        .chilren-black-bg {
          background-color: rgba(0, 0, 0, 0.6);
          //top: 0px!important;
          z-index: -1;

          .chilren-li {
            color: #ffffff;
            &:hover {
              color: #bdbdbd;
            }
          }
        }
        .h-80 {
          transition: 0.2s all ease-in-out;
        }
        li:hover {
          .h-80 {
            position: absolute;
            top: 0;
            left: 0;
            width: 160px;
            height: 80px;
            background-color: rgba(0, 0, 0, 0.6);
            z-index: -1;
          }
        }
      }
      /* 语言选择 */
      .el-dropdown {
        text-align: center;
        width: 5rem;
        font-size: 0.85rem;
        padding: 0.3rem 0.5rem 0.4rem;
        border: 1px solid #ffffff;
        color: #fff;
        border-radius: 100px;
      }
      .eStyle {
        width: 7rem;
      }
    }
  }
}
.children-nav {
  display: none;
  height: 3.75rem;
  line-height: 3.75rem;
}

.a:hover .children-nav {
  display: flex;
}

// /deep/ .el-dropdown-menu__item{
//   width: 80px !important;
//   padding: unset !important;
//   text-align: center !important;
// }

.befor-bg {
  background-color: transparent;
}
.after-bg {
  background-color: #fff;
}
.befor-color {
  color: #fff;
}
.after-color {
  color: #1d3e83;
}
.befor-bac {
  background-color: #fff;
}
.after-bac {
  background-color: #1d3e83;
}
</style>
