<template>
  <div class="horizontal">
    <div class="ho-title">{{ title }}</div>
    <div class="ho-line"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "标题",
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
.horizontal {
  margin: 80px 0 40px;
  text-align: center;
  .ho-title {
    font-size: 36px;
    font-weight: bold;
    color: #2d3033;
    line-height: 72px;
  }
  .ho-line {
    width: 80px;
    height: 6px;
    background: #2d3033;
    margin: 0 auto;
  }
}
</style>