import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { WOW } from 'wowjs'
import 'animate.css'

Vue.config.productionTip = false

// 引入公共样式
import "@/style/common.scss";

// 引入公共组件
import comTitle from "@/components/com-title"
import animationBtn from "@/components/animation-btn"
import horizontalTitle from "@/components/horizontal-title"

Vue.component('comTitle',comTitle)
Vue.component('animationBtn',animationBtn)
Vue.component('horizontalTitle',horizontalTitle)

// 引入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
Vue.prototype.$wow = function (){
  this.$nextTick(()=>{
    const wow = new WOW()
    wow.init({

      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: false
    })
  })
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
