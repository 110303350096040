import Vue from "vue";
import VueRouter from "vue-router";

// 防止重复点击路由时报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

import layout from "@/layout/index";

const routes = [
  {
    path: "/",
    name: "layout",
    redirect: "/home",
    component: layout,
    children: [
      // 首页
      {
        path: "/home",
        name: "home",
        meta: { title: "首页" },
        component: () => import("@/views/home/index"),
      },
      {
        meta: { title: "案例展示" },
        path: "/example",
        name: "Example",
        component: () => import("@/views/example/index"),
      },
      {
        meta: { title: "案例展示" },
        path: "/exampleDetail",
        name: "ExampleDetail",
        component: () => import("@/views/example/detail"),
      },
      {
        meta: { title: "关于我们" },
        path: "/about",
        name: "about",
        component: () => import("@/views/about/index"),
      },
      {
        meta: { title: "合作客户" },
        path: "/vessels",
        name: "vessels",
        component: () => import("@/views/vessels/index"),
      },
      // 解决方案
      {
        path: "/solution",
        name: "solution",
        meta: { title: "解决方案" },
        component: () => import("@/views/solution/index"),
        redirect: "/solution/smartMedicalCare",
        children: [
          // 数字孪生
          {
            meta: { title: "数字孪生" },
            path: "/solution/digitalTwin",
            name: "digitalTwin",
            component: () => import("@/views/solution/digitalTwin"),
          },
          // 智慧政务
          {
            meta: { title: "智慧政务" },
            path: "/solution/smartGovernment",
            name: "smartGovernment",
            component: () => import("@/views/solution/smartGovernment"),
          },
          // 智慧医疗
          {
            meta: { title: "智慧医疗" },
            path: "/solution/smartMedicalCare",
            name: "smartMedicalCare",
            component: () => import("@/views/solution/smartMedicalCare"),
          },
          // 智慧乡村
          {
            meta: { title: "智慧乡村" },
            path: "/solution/smartVillage",
            name: "smartVillage",
            component: () => import("@/views/solution/smartVillage"),
          },
          // 系统集成
          {
            meta: { title: "系统集成" },
            path: "/solution/systemIntegration",
            name: "systemIntegration",
            component: () => import("@/views/solution/systemIntegration"),
          },
        ],
      },
    ],
  },
  {
    path: "/*",
    name: "err-404",
    component: () => import("@/views/error-404/index"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_PATH,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash || savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ?  '木风未来科技-' + to.meta.title : '木风未来科技'
  next()
})
export default router;
