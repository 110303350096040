<template>
  <div class="layout">
    <topNav />
    <div class="router-view">
        <router-view />
    </div>
    <footerTail/>
  </div>
</template>

<script>
import topNav from '@/components/top-nav/index'
import footerTail from '@/components/footerTail/index'

export default {
  name: "Layout",
  components:{
    topNav,
    footerTail
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss">
.layout{
    width: 100%;
    height: 100%;
    .router-view{
        flex: 1;
    }
}
</style>