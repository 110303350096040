<template>
    <div class="c-title">
      <div class="c-zn">{{znFont}}</div>
      <div class="c-en">{{enFont}}</div>
    </div>
</template>

<script>
export default {
    props:{
        znFont:{
            default:'主标题',
            type: String
        },
        enFont:{
            default:'English Title',
            type: String
        },
    },
    data(){
        return {

        }
    },
    methods:{

    }
}
</script>

<style lang="scss">
.c-title{
    text-align: center;
    .c-zn{
      font-size: 40px;
      font-weight: bold;
      color: #2D3033;
    }
    .c-en{
      font-size: 20px;
      font-weight: 400;
      color: #1D3E83;
      line-height: 36px;
      opacity: 0.6;
    }
  }
</style>