<template>
    <button class="ta-btn c_btn" @click="downFn">{{text}}</button>
</template>

<script>
export default {
    name: 'animationBtn',
    props:{
        text:{
            default: '查看详情',
            type: String
        },
    },
    data(){
        return {

        }
    },
    methods:{
        downFn(){
            this.$emit('hold')
        }
    }
}
</script>

<style lang="scss">
.ta-btn {
      width: 160px;
      height: 48px;
      border: 1px solid #ffffff;
      background: rgba(0, 0, 0, 0);
      color: #fff;
      overflow: hidden;
      transition: color 0.4s ease-in-out;
      position: relative;
      z-index: 3;
      &:hover {
        cursor: pointer;
        color: #161616;
      }
      
      &::before {
        content: "";
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1em;
        height: 1em;
        color: #333;
        border-radius: 50%;
        background-color: #fff;
        transform-origin: center;
        transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
        transition: transform 0.45s ease-in-out;
      }
    }
    .ta-btn:hover::before{
      transform: translate3d(-50%, -50%, 0) scale3d(15, 15, 15);
    }
</style>