<template>
  <!-- 页尾 -->
  <div class="footer">
    <div class="heart_page">
      <div class="df-box">
        <div class="fo-left">
          <img class="logo" src="@/assets/img/logo.png" alt="" />
          <div class="call-me">联系我们</div>
          <div class="contact mb">
            <img class="co-img" src="@/assets/img/phone.png" alt="" />
            <span class="co-phone">158-8449-9261</span>
          </div>
          <div class="contact">
            <img class="co-img2" src="@/assets/img/envelope.png" alt="" />
            <span class="co-text">bigtree@mufengweilai.com</span>
          </div>
          <div class="contact">
            <img class="co-img2" src="@/assets/img/address.png" alt="" />
            <span class="co-text">四川省成都市高新区茂业中心B座</span>
          </div>
        </div>
        <div class="fo-right">
          <div class="footer-link">
            <div class="fo-title">解决方案</div>
            <div
              class="pointer"
              @click="$router.push('/solution/smartMedicalCare')"
            >
              智慧医疗
            </div>
            <div
              class="pointer"
              @click="$router.push('/solution/smartGovernment')"
            >
              智慧政务
            </div>
            <div
              class="pointer"
              @click="$router.push('/solution/smartVillage')"
            >
              智慧乡村
            </div>
            <div
              class="pointer"
              @click="$router.push('/solution/systemIntegration')"
            >
              系统集成
            </div>
            <div class="pointer" @click="$router.push('/solution/digitalTwin')">
              数字孪生
            </div>
          </div>
          <div class="footer-link">
            <div class="fo-title">关于我们</div>
            <div class="pointer" @click="$router.push('/home')">网站首页</div>
            <div class="pointer" @click="$router.push('/example')">
              案例展示
            </div>
            <div class="pointer" @click="$router.push('/about')">关于我们</div>
          </div>
          <div>
            <div class="fo-title">友情链接</div>
            <p>
              <a
                class="pointer fo-text"
                href="https://web.kaiyue99.com/dist/index.html#/home"
                target="_blank"
                >开约酒庄</a
              >
            </p>
            <p>
              <a
                class="pointer fo-text"
                href="https://shop.zbj.com/24462462/works-p4.html"
                target="_blank"
                >猪八戒</a
              >
            </p>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="re-box">
        <a
          class="record"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          >Copyright © 2023 备案号：蜀ICP备20007725号-2</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerTail",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
.footer {
  width: 100%;
  height: 420px;
  background: #0d0d2a;
  padding-top: 40px;
  min-width: 1200px;
  .df-box {
    display: flex;
    .fo-left {
      width: 460px;
      border-right: 1px solid #cccccc;
      .logo {
        width: 260px;
        height: 42px;
      }
    }
    .call-me {
      font-size: 24px;
      color: #ffffff;
      line-height: 36px;
      margin: 30px 0 36px;
    }
    .contact {
      display: flex;
      align-items: center;
      &.mb {
        margin-bottom: 20px;
      }
      .co-img {
        width: 32px;
        height: 28px;
        margin-right: 20px;
      }
      .co-phone {
        font-size: 32px;
        font-weight: bold;
        color: #ffffff;
        line-height: 43px;
      }
      .co-img2 {
        width: 24px;
        height: 24px;
        margin-right: 15px;
      }
      .co-phone {
        font-size: 32px;
        font-weight: bold;
        color: #ffffff;
        line-height: 43px;
      }
      .co-text {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        line-height: 43px;
      }
    }
    .fo-right {
      display: flex;
      flex: 1;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 400;
      color: #cccccc;
      line-height: 40px;
      padding-left: 180px;
      .footer-link {
        .pointer {
          &:hover {
            color: #ffffff;
          }
        }
      }
      .fo-title {
        font-size: 24px;
        color: #ffffff;
        line-height: 56px;
      }
      .fo-text {
        font-size: 18px;
        font-weight: 400;
        color: #237bff;
      }
    }
  }
  .line {
    margin-top: 60px;
    width: 100%;
    height: 1px;
    background: #5c5c66;
  }
  .re-box {
    text-align: center;

    .record {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #cccccc;
      line-height: 36px;
      text-align: center;
    }
  }
}
</style>
