var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex_box flex_center",class:_vm.isBg ? 'befor-bg' : 'after-bg',attrs:{"id":"header"}},[_c('div',{staticClass:"content flex_box flex_between"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isBg),expression:"isBg"}],staticClass:"logo pointer",attrs:{"src":require("@/assets/img/logo.png"),"alt":""},on:{"click":function($event){return _vm.toPage('/home')}}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isBg),expression:"!isBg"}],staticClass:"logo pointer",attrs:{"src":require("@/assets/img/logo1.png"),"alt":""},on:{"click":function($event){return _vm.toPage('/home')}}}),_c('div',{staticClass:"content-right flex_box"},[_c('ul',{staticClass:"my-navbar nav-i flex_box flex_around font_18"},_vm._l((_vm.navList),function(item,index){return _c('li',{key:index,class:'color_white title_font ' +
            (item.children ? 'is-li' : '') +
            (_vm.isBg ? ' befor-color' : ' after-color'),on:{"click":function($event){return _vm.toPage(item.path)}}},[_c('span',{style:(_vm.$route.path.includes(item.path)
                ? 'font-size:1.25rem;opacity: 1'
                : 'font-size:1.13rem;opacity:.9')},[_vm._v(_vm._s(_vm.onLang == 1 ? item.name : item.ename))]),_c('div',{class:(_vm.$route.path.includes(item.path) ? 'solids' : 'solids-s') +
              (_vm.isBg ? ' befor-bac' : ' after-bac')}),(_vm.isBg && item.children)?_c('div',{staticClass:"h-80"}):_vm._e(),_c('ul',{class:{
              'chilren-nav': true,
              'chilren-nav-bg': !_vm.isBg,
              'chilren-black-bg': _vm.isBg,
            }},_vm._l((item.children),function(i){return _c('li',{key:i.type,staticClass:"chilren-li cchilren-li flex flex-col-center",on:{"click":function($event){$event.stopPropagation();return _vm.toPage(item.path, i.path)}}},[_c('i',{class:{
                  'nav-active': _vm.$route.path.includes(i.path),
                  'nav-next-active': _vm.$route.path.includes(i.path) && !_vm.isBg,
                }},[_vm._v(_vm._s(_vm.onLang == 1 ? i.name : i.ename))])])}),0)])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }